import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const mendicitesilencieuse = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Mendicité silencieuse" />
    <h3 className='underline-title'>Mendicité silencieuse</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        J'ai écouté bien longtemps<br />
        Tout est calme autour de moi<br />
        Silence, engendre-moi la poésie<br />
        Puis-je me permettre un cri?<br />
        Poésie, transmets-moi ton verbe muet<br />
        Apprends-moi l'immobilité en mouvement<br />
        Dote-moi d'inertie créatrice<br />
        Protège-moi du harcèlement de faux problèmes<br />
        Apprends-moi à oeuvrer sans agir<br />
        Rends-moi donc sage<br />
      </p>
      <p>
        Que je sache que je ne sais rien<br />
        Que je ne sois pas seulement celui qui sait<br />
        Que je sache l'Essence du Mouvement<br />
        Que je sache l'Energie de l'Inertie<br />
        Que je sache la Plénitude du Vide<br />
      </p>
      <p>
        Que je sois l'oiseau réel<br />
        Celui qui vole et se pose<br/>
        Que je sois de deux sexes<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 25 janvier 1976</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default mendicitesilencieuse
